import './developer_tooling.js';
import { Sandwich, pre_id, safe_name, unknown_indicator } from './sandwich';
import bagelData from './data.json';
console.log(bagelData);

function set_classes_to_selected(el: HTMLElement) {
  for (const unsel_class of bagelData.item_classes_unselected.split(' ')) {
    el.classList.remove(unsel_class);
  }
  for (const sel_class of bagelData.item_classes_selected.split(' ')) {
    el.classList.add(sel_class);
  }
}

function set_classes_to_unselected(el: HTMLElement) {
  for (const sel_class of bagelData.item_classes_selected.split(' ')) {
    el.classList.remove(sel_class);
  }
  for (const unsel_class of bagelData.item_classes_unselected.split(' ')) {
    el.classList.add(unsel_class);
  }
}


function mark_item(pi: pre_id, id: string) {
  let safeId = safe_name(id);
  let element: HTMLElement | null = document.getElementById(pi.valueOf() + "-" + safeId);
  if (element instanceof HTMLElement) {
    // console.log(element.classList);
    set_classes_to_selected(element); 
    console.log("marked " + pi.valueOf() + '-' + safeId);
  } else {
    console.log("33 could not locate " + pi.valueOf() + '-' + safeId);
  }

  // unhide the span:
  // let marker_element: HTMLElement | null = document.getElementById(pi.valueOf() + "-" + safeId + '-marker');
  // if (marker_element instanceof HTMLElement) {
  //   // console.log(element.classList);
  //   marker_element.classList.remove("hidden");
  // } else {
  //   console.log("21 could not locate " + pi.valueOf() + '-' + safeId + '-marker');
  // }
  
}

function unmark_all() {
  // console.log("unmark all");
  for (let bagel_id of bagelData.bagels) {
    unmark_item(pre_id.bagel, bagel_id);    
  }

  for (const cc_obj of bagelData.cream_cheese) {
    unmark_item(pre_id.cc, cc_obj.name);
  }

  for (const meat_obj of bagelData.meats) {
    unmark_item(pre_id.meat, meat_obj.name);
  }

  for (const top_obj of bagelData.toppings) {
    unmark_item(pre_id.top, top_obj.name);
  }
}

function unmark_item(pi: pre_id, id: string) {
  let safeId = safe_name(id);
  let element: HTMLElement | null = document.getElementById(pi.valueOf() + "-" + safeId);
  if (element instanceof HTMLElement) {
    // console.log(element.classList);
    set_classes_to_unselected(element);
  } else {
    console.log("34 could not locate " + pi.valueOf() + '-' + safeId);
  }

  // hide the span?
}

let sandwich = new Sandwich();

function action_button_clicked(_) {
  // console.log("select topping: " + id);
  sandwich.randomize();
  update_chart_selections();
  let element: HTMLElement | null = document.getElementById("action_call");
  element.innerText = "Roll again"
}

function bagel_clicked(id: string) {
  // console.log("select bagel: " + id);
  sandwich.toggleBagel(id);
  // mark_item(pre_id.bagel, id);
  update_chart_selections();
}

function cr_cheese_clicked(id: string) {
  // console.log("select cream cheese: " + id);
  sandwich.toggleCreamCheese(id);
  update_chart_selections();
}

function meat_clicked(id: string) {
  // console.log("select meat: " + id);
  sandwich.toggleMeat(id);
  update_chart_selections();
}

function top_clicked(id: string) {
  // console.log("select topping: " + id);
  sandwich.toggleTopping(id);
  update_chart_selections();
}

function update_chart_selections() {
  console.log(sandwich);
  unmark_all();

  if (sandwich.bagel != unknown_indicator) {
    mark_item(pre_id.bagel, sandwich.bagel);
  }

  if (sandwich.cream_cheese != unknown_indicator) {
    mark_item(pre_id.cc, sandwich.cream_cheese);
  }
  
  if (sandwich.meats.length > 0) {
    for (const meat_id of sandwich.meats) {
      mark_item(pre_id.meat, meat_id);
    }
  }
  
  if (sandwich.toppings.length > 0) {
    for (const top_id of sandwich.toppings) {
      mark_item(pre_id.top, top_id);
    }
  }
  
  // #map
}

type ClickFunction = (a: string) => void;

function register_item_listener(pi: pre_id, id: string, click_func: ClickFunction) {
  let safeId = safe_name(id);
  let item_id = pi.valueOf() + "-" + safeId;
  register_listener(item_id, click_func, id);
}

function register_listener(id: string, click_func: ClickFunction, passed_arg: string) {
  var element: HTMLElement | null = document.getElementById(id);
  if (element instanceof HTMLElement) {
    element.onclick = function() {
      click_func(passed_arg);
    };
  } else {
    console.log("issue on " + id);
  }
}

// Register listeners
register_listener("action_call", action_button_clicked, null);

for (let bagelName of bagelData.bagels) {
  register_item_listener(pre_id.bagel, bagelName, bagel_clicked);
}

for (let cc_obj of bagelData.cream_cheese) {
  // let cc_id = safe_name(cc_obj.name);
  register_item_listener(pre_id.cc,cc_obj.name, cr_cheese_clicked);
}

for (let meat_obj of bagelData.meats) {
  // let cc_id = safe_name(cc_obj.name);
  register_item_listener(pre_id.meat,meat_obj.name,meat_clicked);
}

for (let top_obj of bagelData.toppings) {
  // let cc_id = safe_name(cc_obj.name);
  register_item_listener(pre_id.top,top_obj.name,top_clicked);
}

// console.log("complete!");
