import bagelData from './data.json';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

async function write_words_slowly(text: string, element: HTMLElement){
  var content = "";
  for (let t of text.split(' ')) {
    content += t + " ";
    element.textContent = content;
    await sleep(100); 
  }
}

export const unknown_indicator = "???";
export const bagel_pre = "bagel";
export const creamcheese_pre = "cc";
export const meat_pre = "meat";
export const topping_pre = "top";

export enum pre_id {
  bagel = bagel_pre,
  cc = creamcheese_pre,
  meat = meat_pre,
  top = topping_pre
}

export function safe_name(name: string): string {
  return name.toLowerCase()
    .replaceAll(' ', '-');
}
// export enum BagelType {
//   Plain = "plain",
//   Sesame = "sesame",
//   Poppy = "poppy",
//   Onion = "onion",
//   Garlic = "garlic",
//   Salt = "salt",
//   Everything = "everything",
//   WholeWheat = "100% whole wheat",
//   EverythingWheat = "everything wheat",
//   CinnamonRaisin = "cinnamon raisin"
// }

function validateBagel(b: string): boolean {
  if (bagelData.bagels.includes(b)) {
    return true;
  } else {
    console.log("Unknown bagel type: " + b);
  }
}

function starts_with_vowel(query: string) : boolean {
  if (query.length > 0) {
    let first_char_lower = query[0].toLowerCase();
    switch (first_char_lower) {
      case "a":
        return true;
      case "e":
        return true;
      case "i":
        return true;
      case "o":
        return true;
      case "u":
        return true;
    }
  }
  return false;
}

function sentence_capitalization(query: string) : string {
  if (query.length > 0) {
    let start = query[0].toUpperCase();
    let rest = query.slice(1);
    return start + rest;
  } else {
    return "";
  }
}

function safe_add_item(item: string, list: string[]): string[] {
  const idx = list.indexOf(item);
  if (idx < 0) {
    list.push(item);
  }
  return list
}

function removeItem(item: string, list: string[]): string[] {
  let result = list.filter(obj => obj !== item);
  return result;
}

function getRandomItem<T>(arr: T[]): T {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

// function log_to_console() {
//   console.log("Sandwich: ");
//   console.log({
//     "bagel": this.bagel
//   })
// }

export class Sandwich {
  bagel: string = unknown_indicator;
  cream_cheese: string = unknown_indicator;
  meats: string[] = [];
  toppings: string[] = [];

  selectBagel(b: string){
    validateBagel(b);
    this.bagel = b;
    this.write_out();
  }

  deselectBagel() {
    this.bagel = unknown_indicator;
    this.write_out();
  }

  toggleBagel(b: string) {
    if (b == this.bagel ) {
      this.deselectBagel();
    } else {
      this.selectBagel(b);
    }
  }

  selectCreamCheese(c: string) {
    this.cream_cheese = c;
    this.write_out();
  }

  deselectCreamCheese() {
    this.cream_cheese = unknown_indicator;
    this.write_out();
  }

  toggleCreamCheese(cc: string) {
    if (cc == this.cream_cheese) {
      this.deselectCreamCheese();
    } else {
      this.selectCreamCheese(cc);
    }
  }
  
  selectMeat(m: string) {
    this.meats = safe_add_item(m, this.meats);
    this.meats.sort();
    this.write_out();
  }

  deselectMeat(m: string) {
    this.meats = removeItem(m, this.meats);
    this.meats.sort();
    this.write_out();
  }

  toggleMeat(m: string) {
    if (this.meats.indexOf(m) != -1) {
      this.deselectMeat(m);
    } else {
      this.selectMeat(m);
    }
  }

  selectTopping(t: string) {
    this.toppings = safe_add_item(t, this.toppings);
    this.toppings.sort();
    this.write_out();
  }

  deselectTopping(t: string) {
    this.toppings = removeItem(t, this.toppings);
    this.toppings.sort();
    this.write_out();
  }

  toggleTopping(t: string) {
    if (this.toppings.indexOf(t) != -1) {
      this.deselectTopping(t);
    } else {
      this.selectTopping(t);
    }
  }

  randomize() {
    console.log("Randomizing...");
    this.bagel = getRandomItem(bagelData.bagels);
    this.cream_cheese = getRandomItem(bagelData.cream_cheese).name;

    const num_meats = Math.floor(Math.random() * 2);
    this.meats = [];
    for (var _i = 0; _i < num_meats; _i++) {
      const new_meat = getRandomItem(bagelData.meats).name;
      this.meats = safe_add_item(new_meat, this.meats);
    }

    const num_toppings = Math.floor(Math.random() * 4);
    this.toppings = [];
    for (var _i = 0; _i < num_toppings; _i++) {
      const new_topping = getRandomItem(bagelData.toppings).name;
      this.toppings = safe_add_item(new_topping, this.toppings);
    }

    this.write_out();
  }

  prompt_str(): string {
    var words: string[] = [];
    if (this.cream_cheese == unknown_indicator) {
      let preposition = "a";
      if (starts_with_vowel(this.bagel)) {
        preposition = "an";
      }
      words.push(preposition);
      words.push(this.bagel);
      words.push("bagel");
    } else {
      words.push(this.cream_cheese);      
      words.push("cream");
      words.push("cheese");
      words.push("on");
      let preposition = "a";
      if (starts_with_vowel(this.bagel)) {
        preposition = "an";
      }
      words.push(preposition);
      words.push(this.bagel);
      words.push("bagel");
    }

    let sandwich_comps: string[] = [];

    for (const meat_name of this.meats) {
      sandwich_comps.push(meat_name);
    }

    for (const top_name of this.toppings) {
      sandwich_comps.push(top_name);
    }

    if (sandwich_comps.length > 0) {
      words.push("with");
      for (var sc_idx=0; sc_idx < sandwich_comps.length; sc_idx += 1) {
        const item_name = sandwich_comps[sc_idx];
        let new_word = item_name;
        if (sc_idx != sandwich_comps.length - 1) {
          if (sc_idx < sandwich_comps.length - 2) {
            new_word = new_word.concat(",");
          } else if (sc_idx == sandwich_comps.length - 2) {
            if (sandwich_comps.length == 2) {
              new_word = new_word.concat(" and");
            } else {
              new_word = new_word.concat(", and");
            }
          }
        }
        
        words.push(new_word);
      }
    }

    var prompt = words.join(' ');
    let final_prompt = sentence_capitalization(prompt);
    return final_prompt;
  }

  write_out() {
    let message = this.prompt_str();
    let prompt = document.getElementById("prompt");

    write_words_slowly(message, prompt);
  }
}
